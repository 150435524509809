<template>
    <Card class="border-purple-600">
        <template v-slot:default>
            <h3 class="card__title">{{ rentableItem.name }}</h3>
        </template>

        <template v-slot:footer>
            <div class="flex justify-between">
                <div class="flex flex-col">
                    <Rate
                        :value="rentableItem.timeslot.rate"
                        :unit="rentableItem.timeslot.unit"
                    />
                    <Rate
                        :value="rentableItem.deposit?.amount ?? 0"
                        :unit="DEPOSIT_FIELD"
                    />
                </div>
                <RentableItemAvailability
                    :available="rentableItem.itemsCounter.available"
                    :total="rentableItem.itemsCounter.total"
                />
            </div>
        </template>
    </Card>
</template>

<script>
import Card from '@/components/ui/Card';
import Rate from '@/components/ri/Rate';
import RentableItemAvailability from '@/components/ri/RentableItemAvailability';
import { DEPOSIT_FIELD } from '@/views/rentableItems/constants';

export default {
    components: { Card, Rate, RentableItemAvailability },

    props: {
        rentableItem: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            DEPOSIT_FIELD,
        };
    },

    computed: {
        hasLocationChain() {
            const { locationsChain } = this.rentableItem;
            return Array.isArray(locationsChain) && locationsChain.length;
        },
    },
};
</script>

<style scoped>
.card__title {
    @apply text-gray-700 font-ffdin font-bold text-base mb-3 mt-1 overflow-hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
