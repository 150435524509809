<template>
    <ContentArea
        :watch-for-scroll="true"
        :loading="loading"
        contentClass="bg-gray-200 px-6"
        @reachedBottom="loadMore()"
    >
        <template v-slot:header>
            <Tabs
                outlined
                @changed="handleStatusUpdate"
            >
                <Tab
                    v-for="(option, index) of statusOptions"
                    :key="index"
                    :title="option.value"
                />
            </Tabs>
        </template>

        <div
            v-for="item of items"
            :key="item.value.id"
            class="reservations__card"
        >
            <router-link
                v-if="hasPermission('RP_RESERVATIONS_VIEW_DETAILS')"
                :to="{ name: 'reservations.details', params: { reservationId: item.value.id } }"
            >
                <ReservationCard :reservation="item.value" />
            </router-link>
            <ReservationCard
                v-else
                :reservation="item.value"
            />
        </div>

        <div
            v-if="!loading && items.length === 0"
            class="reservations__empty"
        >
            <icon
                name="empty"
                class="reservations__empty-icon"
            />
            <div class="reservations__empty-text">{{ $t('ri.reservations.list.is_empty') }}</div>
        </div>
    </ContentArea>
</template>

<script>
import { mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import RefreshPageDataMixin from '@/mixins/RefreshPageDataMixin';
import ContentArea from '@/components/ui/ContentArea';
import ReservationCard from '@/components/ri/ReservationCard';
import Icon from '@/components/ui/Icon';
import InfiniteScrollMixin from '@/mixins/InfiniteScrollMixin';
import AuthMixin from '@/mixins/AuthMixin';
import { STATUS_GROUP_PENDING, STATUS_GROUP_ACTIVE, STATUS_GROUP_COMPLETED, STATUS_GROUP_CANCELED, SORT_BACK } from './constants';
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';

export default {
    components: { Icon, ContentArea, ReservationCard, Tabs, Tab },

    mixins: [AuthMixin, InfiniteScrollMixin, RefreshPageDataMixin],

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.reservationChanged && to.name === 'reservations.index') {
                vm.loadInitial();
                vm.setReservationChanged(false);
            }
        });
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            statusOptions: [
                { key: 'ACTUAL', value: this.t('ri.reservations.list.status_groups.actual') },
                { key: STATUS_GROUP_COMPLETED, value: this.t('ri.reservations.list.status_groups.completed') },
                { key: STATUS_GROUP_CANCELED, value: this.t('ri.reservations.list.status_groups.canceled') },
            ],

            status: 'ACTUAL',
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'ri/hasPermission',
        }),

        preparedStatuses() {
            if (this.status === 'ACTUAL') {
                return [STATUS_GROUP_ACTIVE, STATUS_GROUP_PENDING];
            }

            return [this.status];
        },

        sortBy() {
            return SORT_BACK;
        },
    },

    watch: {
        status() {
            this.loadInitial();
        },
    },

    methods: {
        loadItems(options = {}) {
            return this.$riDataProvider.getList('reservations', { statuses: this.preparedStatuses, dir: this.sortBy, ...options });
        },

        handleStatusUpdate(activeTab) {
            this.status = this.statusOptions[activeTab].key;
        },

        refreshPageData() {
            this.loadInitial();
        },
    },
};
</script>

<style scoped>
.reservations__empty {
    @apply flex flex-col items-center justify-center w-full h-full;
}

.reservations__empty-icon {
    @apply mb-8;
    width: 286px;
    height: 269px;
}

.reservations__empty-text {
    @apply text-sm text-gray-400;
}

.reservations__card {
    @apply mt-4;
}
</style>
