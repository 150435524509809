<template>
    <div class="rentable-items">
        <div
            v-for="item of items"
            :key="item.value.id"
            class="rentable-items__card"
        >
            <router-link
                v-if="hasPermission('RP_RI_VIEW')"
                :to="{ name: 'rentableItems.details', params: { riId: item.value.id } }"
            >
                <RentableItemCard :rentableItem="item.value" />
            </router-link>

            <RentableItemCard
                v-else
                :rentableItem="item.value"
            />
        </div>
        <div
            v-if="loading"
            class="text-center"
        >
            {{ $t('app.content_loading') }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RentableItemCard from '@/components/ri/RentableItemCard';
import InfiniteScrollMixin from '@/mixins/InfiniteScrollMixin';

export default {
    components: { RentableItemCard },

    mixins: [InfiniteScrollMixin],

    props: {
        categoryId: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'ri/hasPermission',
        }),
    },

    methods: {
        loadItems(options = {}) {
            return this.$riDataProvider.getList('rentableItems', { categoryId: this.categoryId, ...options });
        },
    },
};
</script>

<style scoped>
.rentable-items__card {
    @apply mt-4;
}

.rentable-items__card:first-child {
    @apply mt-0;
}
</style>
