import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('auth');

export default {
    computed: mapGetters(['currentProfile', 'appsPermissions']),

    watch: {
        currentProfile() {
            this.unwatchPermissions = this.$watch(
                'appsPermissions',
                () => {
                    this.$nextTick(() => {
                        if (this.unwatchPermissions) {
                            this.refreshPageData();

                            this.unwatchPermissions();
                            this.unwatchPermissions = null;
                        }
                    });
                },
                {
                    deep: true,
                }
            );
        },
    },

    unmounted() {
        if (this.unwatchPermissions) {
            this.unwatchPermissions();
            this.unwatchPermissions = null;
        }
    },

    methods: {
        refreshPageData() {},
    },
};
