<template>
    <div class="tab-panel">
        <!-- @slot Tab content -->
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        /**
         * Tab heading
         */
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
