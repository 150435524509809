<template>
    <div
        class="collapsible"
        :class="[finalCss.collapsibleClass, expanded ? finalCss.expandedClass : '', { 'collapsible--expanded': expanded }]"
    >
        <div
            ref="heading"
            class="collapsible__heading"
            :class="[finalCss.headingClass, expanded ? finalCss.headingExpandedClass : '']"
            @click="handleClick"
        >
            <!-- @slot Heading block -->
            <slot name="heading" />
            <Icon
                name="chevron-down"
                class="collapsible__arrow"
                :class="finalCss.arrowClass"
            />
        </div>
        <div
            v-if="expanded"
            class="collapsible__content"
            :class="finalCss.contentClass"
        >
            <!-- @slot Content block -->
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

const css = {
    collapsibleClass: 'w-full border border-transparent',
    expandedClass: '',
    headingClass: '',
    headingExpandedClass: '',
    contentClass: '',
    arrowClass: 'w-3 h-3 text-gray-450',
};

export default {
    name: 'Collapsible',

    components: {
        Icon,
    },

    props: {
        /**
         * Initial expanded state value
         */
        initialExpanded: {
            type: Boolean,
            default: false,
        },

        /**
         * Custom CSS styles
         */
        css: {
            type: Object,
            default() {
                return css;
            },
        },
    },

    emits: ['click'],

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        finalCss() {
            return { ...css, ...this.css };
        },
    },

    watch: {
        initialExpanded: {
            handler: function () {
                this.expanded = this.initialExpanded;
            },

            immediate: true,
        },
    },

    methods: {
        handleClick() {
            this.expanded = !this.expanded;
            /**
             * Emitted when the heading panel was clicked
             */
            this.$emit('click');
        },
    },
};
</script>

<style scoped>
.collapsible--expanded > .collapsible__heading .collapsible__arrow {
    transform: rotate(180deg);
}

.collapsible__heading {
    @apply flex items-center cursor-pointer;
}
</style>
