<template>
    <Card>
        <template v-slot:default>
            <div class="card__content">
                <div class="mr-4">
                    <h3 class="title">{{ reservation.ri.name }}</h3>
                    <div class="description">
                        {{ itemDetails }}
                    </div>
                </div>

                <ReservationStatus
                    v-if="reservation.status"
                    :status="reservation.status"
                    uppercase
                />
            </div>
        </template>

        <template v-slot:footer>
            <div class="card__footer">
                <div class="flex flex-col">
                    <Rate
                        :value="reservation.price.rate"
                        :unit="reservation.price.unit"
                    />
                    <Rate
                        :value="reservation.deposit?.amount ?? 0"
                        :unit="DEPOSIT_FIELD"
                    />
                </div>

                <div
                    v-if="reservation.status === canceledStatus"
                    class="period__labels"
                >
                    <div>{{ $t(`ri.reservations.fields.cancellation_date`) }}</div>
                    <div>{{ cancellationDate }}</div>
                </div>

                <div v-else>
                    <div class="period">
                        <div class="period__labels period__labels--fixed">{{ $t(`ri.reservations.fields.from`) }}</div>
                        <div class="period__values">{{ startDate }}</div>
                    </div>

                    <div class="period">
                        <div class="period__labels period__labels--fixed">{{ $t(`ri.reservations.fields.to`) }}</div>
                        <div class="period__values">{{ endDate }}</div>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import { useI18n } from 'vue-i18n';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import Card from '@/components/ui/Card';
import Rate from '@/components/ri/Rate';
import ReservationStatus from '@/components/ri/ReservationStatus';
import { STATUS_GROUP_CANCELED } from '@/views/rentableItems/constants';
import { DEPOSIT_FIELD } from '@/views/rentableItems/constants';

const dateFormat = 'MM/DD/YYYY, hh:mm\xA0a';

export default {
    components: { Card, ReservationStatus, Rate },

    mixins: [CommunityTimezoneMixin],

    props: {
        reservation: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            DEPOSIT_FIELD,
        };
    },

    computed: {
        startDate() {
            return this.formatDateTime(this.reservation.start, dateFormat);
        },

        endDate() {
            return this.formatDateTime(this.reservation.end, dateFormat);
        },

        cancellationDate() {
            return this.formatDateTime(this.reservation.updatedAt, dateFormat);
        },

        itemDetails() {
            return this.reservation.item?.name;
        },
    },

    created() {
        this.canceledStatus = STATUS_GROUP_CANCELED;
    },
};
</script>

<style scoped>
.title {
    @apply text-gray-700 font-ffdin font-bold text-base mb-3 mt-1;
}

.description {
    @apply font-ffdin text-md font-medium;
}

.title,
.description {
    @apply overflow-hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card__content {
    @apply flex justify-between items-start;
}

.card__footer {
    @apply flex justify-between items-center;
}

.period {
    @apply flex;
}

.period__values {
    @apply text-gray-700 text-xs tracking-wide font-sofia ml-2;
}

.period__labels {
    @apply text-xs text-gray-500 font-sofia tracking-wide text-right;
}

.period__labels--fixed {
    @apply w-7;
    min-width: 1.75rem;
}
</style>
