<template>
    <div class="rentable-items-categories">
        <template v-if="!loading">
            <Collapsible
                v-for="(category, index) in filledCategories"
                :key="category.id"
                ref="collapsible"
                :css="getCategoryCss(index)"
                @click="handleCategoryClick(index)"
            >
                <template v-slot:heading>
                    <div class="flex items-center flex-grow">
                        <div class="flex-grow flex justify-between items-center">
                            <h5 class="font-ffdin font-bold text-xs uppercase text-gray-700">{{ category.name }}</h5>
                            <div class="text-md font-sofia text-gray-700 mr-4 leading-4">{{ category.riCount }}</div>
                        </div>
                    </div>
                </template>

                <template v-slot:content>
                    <RentableItemList
                        ref="rentableItemList"
                        :categoryId="category.id"
                    />
                </template>
            </Collapsible>
        </template>

        <div
            v-if="loading"
            class="text-center"
        >
            {{ $t('app.content_loading') }}
        </div>

        <div
            v-if="!loading && categories.length === 0"
            class="rentable-items-categories__empty"
        >
            <icon
                name="empty-reservations-list"
                class="rentable-items-categories__empty-icon"
            />
            <div class="rentable-items-categories__empty-text">{{ $t('ri.list.is_empty') }}</div>
        </div>
    </div>
</template>

<script>
import Collapsible from '@/components/ui/Collapsible';
import RentableItemList from '@/views/rentableItems/RentableItemList';
import NotifyMixin from '@/mixins/NotifyMixin';
import RefreshPageDataMixin from '@/mixins/RefreshPageDataMixin';
import Icon from '@/components/ui/Icon';

export default {
    components: { Collapsible, RentableItemList, Icon },

    mixins: [NotifyMixin, RefreshPageDataMixin],

    props: {
        activeCategory: {
            type: Number,
        },

        activeCategoryStacking: {
            type: Boolean,
        },
    },

    emits: ['categoryToggled'],

    data: () => ({
        loading: false,
        categories: [],
    }),

    computed: {
        filledCategories() {
            return this.categories.filter(category => category.riCount);
        },
    },

    mounted() {
        this.fetchCategories();
    },

    methods: {
        fetchCategories() {
            this.loading = true;

            this.$riDataProvider
                .getList('categoriesRICount')
                .then(categories => {
                    this.categories = categories;
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => {
                    this.loading = false;
                });
        },

        handleCategoryClick(index) {
            this.$emit('categoryToggled', index);
        },

        getCategoryCss(index) {
            return {
                collapsibleClass: `bg-gray-200 w-full ${this.activeCategoryStacking && 'collapsible--stacking'}`,
                headingClass: `relative bg-white p-6 ${index === this.activeCategory ? 'sticky top-0 z-10' : ''}`,
                headingExpandedClass: this.activeCategoryStacking ? '' : 'shadow-md',
                contentClass: ' py-4 px-6',
                arrowClass: 'w-3 h-3 text-gray-450',
            };
        },

        refreshPageData() {
            this.fetchCategories();
        },
    },
};
</script>

<style scoped>
.rentable-items-categories {
    @apply h-full;
}

.rentable-items-categories :deep(:not(.collapsible--expanded) .collapsible__heading:after),
.rentable-items-categories :deep(.collapsible--stacking .collapsible__heading:after) {
    content: '';
    @apply absolute left-6 right-6 bottom-0 bg-gray-200;
    height: 1px;
}

.rentable-items-categories__empty {
    @apply flex flex-col items-center justify-center w-full h-full;
}

.rentable-items-categories__empty-icon {
    @apply mb-8;
    width: 286px;
    height: 269px;
}

.rentable-items-categories__empty-text {
    @apply text-sm text-gray-400;
}
</style>
