<template>
    <Page
        :title="$t('ri.title')"
        content-class=""
    >
        <div class="h-full">
            <Tabs
                v-if="hasPermission('RP_RESERVATIONS_VIEW')"
                ref="tabs"
                class="flex h-full flex-col"
                navClass="mx-6 mb-4"
                @changed="handleStatusChange"
            >
                <Tab
                    :title="$t('ri.list.title')"
                    class="flex-grow h-full overflow-auto relative"
                >
                    <ContentArea
                        content-class=""
                        :watch-for-scroll="true"
                        @scroll="handleScroll"
                    >
                        <RICategoriesList
                            ref="categoriesTab"
                            :activeCategory="currentStickyCategory"
                            :activeCategoryStacking="stickyCategoryStacking"
                            @categoryToggled="handleCategoryToggled($event)"
                        />
                    </ContentArea>
                </Tab>
                <Tab
                    :title="$t('ri.reservations.title')"
                    class="flex-grow overflow-auto overscroll-contain md:overscroll-auto h-full"
                >
                    <ReservationsList ref="reservationList" />
                </Tab>
            </Tabs>

            <ContentArea
                v-else-if="hasPermission('RP_RI_VIEW')"
                content-class=""
                :watch-for-scroll="true"
                @scroll="handleScroll"
            >
                <RICategoriesList
                    ref="categoriesTab"
                    :activeCategory="currentStickyCategory"
                    :activeCategoryStacking="stickyCategoryStacking"
                    @categoryToggled="handleCategoryToggled($event)"
                />
            </ContentArea>
        </div>
    </Page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import Page from '@/components/ui/Page';
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';
import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from '@/mixins/AuthMixin';
import ReservationsList from '@/views/rentableItems/ReservationsList';
import RICategoriesList from '@/views/rentableItems/RICategoriesList';
import ContentArea from '@/components/ui/ContentArea';
import { rentableItemsTabs } from './constants';

export default {
    components: { Page, Tab, Tabs, ReservationsList, RICategoriesList, ContentArea },

    mixins: [AuthMixin, NotifyMixin],

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name !== 'rentableItems.index') {
                return;
            }

            if (vm.reservationChanged) {
                vm.$refs.reservationList.loadInitial();
                vm.setReservationChanged(false);
            }

            if (vm.$route.hash === '#reservations') {
                vm.$refs.tabs.openTab(1);
            }

            if (vm.createdReservationsCount) {
                vm.notifySuccess(
                    `<div>${vm.t(
                        'ri.reservations.messages.create.success',
                        {
                            reservationsCount: vm.createdReservationsCount,
                        },
                        vm.createdReservationsCount
                    )}</div>
                    ${vm.t('ri.reservations.messages.create.payment')} 
                    <span class="uppercase underline font-ffdin text-sm font-bold tracking-wider">
                    ${vm.t('ri.reservations.messages.create.resident_ledger')}</span>`
                );

                vm.setCreatedReservationsCount(0);
            }
        });
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            statusOptions: [
                { key: rentableItemsTabs.ALL_ITEMS, value: this.t('ri.reservations.list.status_groups.actual') },
                {
                    key: rentableItemsTabs.MY_RESERVATIONS,
                    value: this.t('ri.reservations.list.status_groups.completed'),
                },
            ],

            status: rentableItemsTabs.ALL_ITEMS,
            currentStickyCategory: null,
            stickyCategoryStacking: false,
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'ri/hasPermission',
            reservationChanged: 'ri/reservationChanged',
            createdReservationsCount: 'ri/createdReservationsCount',
        }),
    },

    methods: {
        ...mapActions({
            setReservationChanged: 'ri/setReservationChanged',
            setCreatedReservationsCount: 'ri/setCreatedReservationsCount',
        }),

        handleStatusChange(index) {
            this.status = this.statusOptions[index].key;
        },

        handleScroll(event) {
            if (this.status !== rentableItemsTabs.ALL_ITEMS) return;

            //fixed heading
            const categoriesElements = this.$refs.categoriesTab.$refs.collapsible;
            const containerRect = event.target.getBoundingClientRect();
            const riLists = this.$refs.categoriesTab.$refs.rentableItemList;

            if (typeof this.currentStickyCategory === 'number') {
                const stickyCategoryHeadingRect = categoriesElements[this.currentStickyCategory].$refs.heading.getBoundingClientRect();
                const stickyCategoryRect = categoriesElements[this.currentStickyCategory].$el.getBoundingClientRect();

                if (
                    stickyCategoryRect.top > stickyCategoryHeadingRect.top ||
                    stickyCategoryRect.bottom < stickyCategoryHeadingRect.bottom
                ) {
                    this.currentStickyCategory = null;
                }

                this.stickyCategoryStacking = stickyCategoryHeadingRect.top < containerRect.top;
            }

            categoriesElements.map((category, index) => {
                const headingRect = category.$refs.heading.getBoundingClientRect();

                if (
                    category.expanded &&
                    headingRect.top < containerRect.top &&
                    category.$el.getBoundingClientRect().bottom > containerRect.top
                ) {
                    this.currentStickyCategory = index;
                }
            });

            //check for loading
            if (Array.isArray(riLists)) {
                riLists.map(riList => {
                    const riBottom = riList.$el.getBoundingClientRect().bottom;

                    if (riBottom <= containerRect.bottom && riList.hasMore && !riList.loading) {
                        riList.loadMore();
                    }
                });
            }
        },

        handleCategoryToggled(index) {
            if (index === this.currentStickyCategory) {
                this.currentStickyCategory = null;
                this.$refs.categoriesTab.$refs.collapsible[index].$el.scrollIntoView();
                this.stickyCategoryStacking = false;
            }
        },
    },
};
</script>
