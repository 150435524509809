<template>
    <div class="ri-availability">
        <span class="ri-availability__label">{{ availabilityLabel }}</span>
        <AvailabilityChart
            :total="total"
            :available="available"
        />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import AvailabilityChart from '@/components/ri/AvailabilityChart';

export default {
    components: { AvailabilityChart },

    props: {
        /**
         * Available amount
         */
        available: {
            type: Number,
        },

        /**
         * Total amount
         */
        total: {
            type: Number,
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        availabilityLabel() {
            if (!this.available) {
                return this.t('ri.availability.leased');
            } else {
                return this.t('ri.availability.available', { num: this.available });
            }
        },
    },
};
</script>

<style scoped>
.ri-availability {
    @apply flex items-center;
}

.ri-availability__label {
    @apply font-sofia text-xs text-gray-700 mr-2 lowercase;
}
</style>
