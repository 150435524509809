<template>
    <div class="tabs-wrappper">
        <div
            class="tabs"
            :class="[{ 'tabs--outlined': outlined }, navClass]"
        >
            <button
                v-for="(tab, index) of tabs"
                :key="tab.props.title"
                class="tab"
                :class="{ 'tab--active': index === activeTab }"
                @click="openTab(index)"
            >
                <div class="tab__label">{{ tab.props.title }}</div>
            </button>
        </div>

        <component
            :is="activeTabPanel"
            v-if="activeTabPanel"
            :key="activeTab"
        />
    </div>
</template>

<script>
export default {
    name: 'Tabs',

    props: {
        /**
         * Initial active tab index
         */
        forceTab: {
            type: Number,
        },

        /**
         * Apply outlined style
         */
        outlined: {
            type: Boolean,
        },

        /**
         * Custom class for navigation block
         */
        navClass: {
            type: String,
        },
    },

    emits: [
        /**
         *  Emitted on active tab index update
         */
        'changed',
    ],

    data() {
        return {
            activeTab: 0,
        };
    },

    computed: {
        tabs() {
            if (!this.$slots.default) {
                return [];
            }

            const defaultSlot = this.$slots.default();

            if (defaultSlot.length === 1 && defaultSlot[0].type.name !== 'Tab') {
                return defaultSlot[0].children;
            }

            return defaultSlot.filter(child => child.type.name === 'Tab');
        },

        activeTabPanel() {
            return this.tabs[this.activeTab];
        },
    },

    watch: {
        activeTab() {
            this.$emit('changed', this.activeTab);
        },
    },

    created() {
        if (this.forceTab != null) {
            this.activeTab = this.forceTab;
        }
    },

    methods: {
        openTab(index) {
            if (!this.tabs[index]) return;

            this.activeTab = index;
        },
    },
};
</script>

<style scoped>
.tabs {
    @apply flex;
}

.tab:first-child {
    @apply rounded-tl-xl rounded-bl-xl;
}

.tab:last-child {
    @apply rounded-tr-xl rounded-br-xl;
}

.tab {
    @apply flex flex-1 justify-center items-center overflow-hidden whitespace-nowrap overflow-ellipsis h-12 border;
    @apply font-sofia font-medium lowercase;
    @apply bg-purple-300 border-purple-200 text-purple-700;
}

.tab:not(.tab--active):hover {
    @apply bg-purple-400 border-purple-400 text-white;
}

.tab--active {
    @apply bg-purple-600 border-purple-600 text-white;
}

.tab:not(:first-child) {
    @apply border-l-0;
}

.tab:not(:last-child) {
    @apply border-r-0;
}

.tab:focus {
    @apply outline-none;
}

.tab__label {
}

/** Outlined mode **/

.tabs--outlined {
}

.tabs--outlined .tab {
    @apply h-14 border-0 bg-none text-gray-500 overflow-visible;
    border-color: transparent;
    background-color: transparent;
}

.tabs--outlined .tab:first-child {
    @apply rounded-tl-none rounded-bl-none;
}

.tabs--outlined .tab:last-child {
    @apply rounded-tr-none rounded-br-none;
}

.tabs--outlined .tab .tab__label {
    @apply flex justify-center items-center relative px-2;
    height: inherit;
}

.tabs--outlined .tab:not(.tab--active):hover {
    @apply bg-transparent border-transparent text-purple-500;
}

.tabs--outlined .tab--active {
    @apply text-purple-600;
    background-color: transparent;
}

.tabs--outlined .tab .tab__label:after {
    @apply absolute left-0 h-full w-full border-b-2;
    content: '';
    border-color: transparent;
    top: 2px;
}

.tabs--outlined .tab--active .tab__label:after {
    @apply border-purple-600;
}
</style>
