<template>
    <div
        class="rate"
        :class="css.rateClass"
    >
        <div
            class="rate__value"
            :class="css.valueClass"
        >
            {{ formattedValue }}
        </div>
        <div
            class="rate__unit-details"
            :class="css.unitClass"
        >
            <span class="rate__delimeter">/</span>
            <span class="rate__unit">{{ unitLabel }}</span>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { mask } from '@/utils/Amount';
import { DEPOSIT_FIELD } from '@/views/rentableItems/constants';

const css = {
    rateClass: 'flex items-center font-sofia',
    valueClass: 'text-purple-600 font-medium text-md tracking-wide',
    unitClass: 'lowercase tracking-wide text-gray-500 text-xs',
};

export default {
    props: {
        /**
         * Rate value
         */
        value: {
            type: [Number, String],
            required: true,
        },

        /**
         * Time unit
         */
        unit: {
            type: String,
            required: true,
        },

        /**
         * Consider unit count
         */
        unitCount: {
            type: Number,
        },

        /**
         * Custom CSS styles
         */
        css: {
            type: Object,
            default() {
                return css;
            },
        },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        formattedValue() {
            return mask(this.value, { prefix: '$' });
        },

        unitLabel() {
            if (this.unit === DEPOSIT_FIELD) {
                return this.t(`ri.deposit.deposit`);
            }

            const unit = this.unit.toLowerCase();

            if (this.unitCount) {
                return `${this.unitCount} ${this.t(`ri.periods.${unit}`, this.unitCount)}`;
            }

            return this.t(`ri.periods.${unit}`);
        },
    },
};
</script>

<style scoped>
.rate__delimeter {
    @apply mx-1;
}
</style>
