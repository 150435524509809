<template>
    <icon
        :name="iconName"
        class="availability-chart"
    />
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    props: {
        /**
         * Total amount
         */
        total: {
            type: [String, Number],
        },

        /**
         * Available amount
         */
        available: {
            type: [String, Number],
        },
    },

    computed: {
        iconName: function () {
            const availability = (this.total - this.available) / this.total;

            if (availability > 0 && availability <= 0.25) {
                return 'circle-quarter';
            } else if (availability > 0.25 && availability <= 0.5) {
                return 'circle-half';
            } else if (availability > 0.5 && availability < 1) {
                return 'circle-three-quarters';
            } else if (availability === 1) {
                return 'circle-filled';
            } else {
                return 'circle-empty';
            }
        },
    },
};
</script>

<style scoped>
.availability-chart {
    @apply w-4 h-4 min-w-4 text-purple-600;
}
</style>
